import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile } from 'styles/breakpoints';
import 'swiper/swiper-bundle.min.css';

import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from 'components/images/DynamicImage';

interface TestimonialsProps {
  banner: string;
  slides: string[];
  bgColor?: string;
  activeColor?: string;
}

const SwiperStyled = styled(Swiper)<{ activeColor: string }>`
  .swiper-slide {
    margin-bottom: 2.5rem;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #d6d6d699;

    &-active {
      background-color: ${({ activeColor }) => activeColor};
      width: 8px;
    }
  }
`;

const SlideContainer = styled.div`
  width: 21.4375rem;
`;

const Container = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  @media ${mobile} {
    padding-top: 0;
  }
`;

const InnerWrapper = styled.div`
  max-width: 44.375rem;
  width: 100%;
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 21.4375rem;
  @media ${mobile} {
    margin: 0 auto;
    width: 18rem;
  }
`;

const Banner = styled(StyledDynamicImage)`
  margin: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 2rem;
  @media ${mobile} {
    margin-bottom: 1.5rem;
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  /* width: auto; */
  /* max-width: 343px; */
`;

const Testimonials: FC<TestimonialsProps> = ({
  banner,
  slides,
  bgColor = '#38724f',
  activeColor = '#FFFFFF',
}) => (
  <Container bgColor={bgColor}>
    <Banner src={banner} alt="Banner" />
    <InnerWrapper>
      <SwiperStyled
        modules={[Pagination]}
        pagination={{ clickable: true }}
        allowTouchMove
        effect="slide"
        slidesPerView={1}
        spaceBetween={24}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
        }}
        activeColor={activeColor}
      >
        {slides.map((review, index) => (
          <SwiperSlideStyled
            style={{
              display: 'flex',
            }}
            key={index}
          >
            <SlideContainer>
              <StyledDynamicImage src={review} alt="Slide" />
            </SlideContainer>
          </SwiperSlideStyled>
        ))}
      </SwiperStyled>
    </InnerWrapper>
  </Container>
);

export default Testimonials;
